import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as LogoCro } from "../../icons/logos/cro.svg";
import { ReactComponent as LogoGauk } from "../../icons/logos/gauk.svg";
import "./Logos.scss";

// TODO: make MySVG or Logo component with name prop
const Logos = ({ logos, heading }) => {
  if (logos.length <= 0) return null;

  const renderLogos = logos.map(logo => {
    switch (logo) {
      case "cro":
        return <LogoCro />;
      case "gauk":
        return <LogoGauk />;
      default:
        return <></>;
    }
  });

  return (
    <div className="Logos">
      {heading && <h5 className="Logos__heading">{heading}</h5>}
      <div className="Logos__item">{renderLogos}</div>
    </div>
  );
};

Logos.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  logos: PropTypes.array.isRequired,
};

export default Logos;
