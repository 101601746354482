import axios from "axios";
import env from "@beam-australia/react-env";
import { getLanguageHeader } from "./i18n";

const _ = require("lodash");

class DataService {
  constructor() {
    axios.defaults.baseURL = env("API_BASEURL") || "";
    axios.defaults.headers.common["Accept-Language"] = getLanguageHeader();
  }

  fetchCatalog(catalogURL, lng) {
    const options = {
      method: "GET",
      params: { lng },
      url: `/api/catalog-specific${catalogURL}`,
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchCatalogExercise(id) {
    return axios
      .get(`/api/catalog/${id}`)
      .then(this.extractDataAndNotifications);
  }

  fetchExerciseList(id) {
    const options = {
      method: "GET",
      url: `/api/group/${id}/exercises`,
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchGroupList() {
    return axios.get("/api/group").then(this.extractDataAndNotifications);
  }

  fetchGroup(id) {
    return axios.get(`/api/group/${id}`).then(this.extractDataAndNotifications);
  }

  fetchGroups() {
    return axios.get("/api/group").then(this.extractDataAndNotifications);
  }

  createGroup(group) {
    const { lastUsedAt, emailAddresses, ...payload } = group;
    const options = {
      method: "POST",
      data: payload,
      url: "/api/group",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  updateGroup(group) {
    const { lastUsedAt, emailAddresses, isDeleted, exerciseCount, ...payload } =
      group;
    if (group.id) {
      const options = {
        method: "PUT",
        data: payload,
        url: `/api/group/${group.id}`,
      };
      return axios(options).then(this.extractDataAndNotifications);
    }
    return this.createGroup(group);
  }

  deleteGroup(group) {
    return axios
      .delete(`/api/group/${group.id}`)
      .then(this.extractDataAndNotifications);
  }

  addEmailAddresses(enrolmentId, emails) {
    const emailAddresses = _.uniq(
      emails.split(/,|\s+|;/).filter(e => e && e.trim().length > 0)
    );
    const options = {
      method: "POST",
      data: { enrolmentId, emailAddresses },
      url: "/api/exercise/emailAddress",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  addEmailAddressesToGroup(groupId, emails) {
    const emailAddresses = emails
      .split(/,|\s+|;/)
      .filter(e => e.trim().length > 0);
    const options = {
      method: "POST",
      data: { groupId, emailAddresses },
      url: "/api/group/emailAddress",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  postHelpdeskHelp(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/helpdesk/help",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  postHelpdeskBug(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/helpdesk/bug",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  deleteEmailAddressFromGroup(groupId, emailAddress) {
    const options = {
      method: "DELETE",
      data: { groupId, ...emailAddress },
      url: "/api/group/emailAddress",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  deleteEnrolment(enrolmentId) {
    const options = {
      method: "DELETE",
      data: { enrolmentId },
      url: "/api/enrolment",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  deleteEmailAddress(enrolmentId, emailAddress) {
    const options = {
      method: "DELETE",
      data: { enrolmentId, emailAddress },
      url: "/api/exercise/emailAddress",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  enrolUserToExercise(exerciseId, group, type) {
    const options = {
      method: "POST",
      data: { exerciseId, groupId: group.id, groupName: group.name, type },
      url: "/api/exercise/enrol",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  enrolUserToPackage(packageId, group, type) {
    const options = {
      method: "POST",
      data: { packageId, groupId: group.id, groupName: group.name, type },
      url: "/api/package/enrol",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchMyExercise(exerciseCode) {
    return axios
      .get(`/api/my/exercise/${exerciseCode}`)
      .then(this.extractDataAndNotifications);
  }

  login(credentials) {
    const options = {
      method: "POST",
      data: credentials,
      url: "/api/login",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  logout() {
    const options = {
      method: "GET",
      url: "/api/logout",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  changeGroupName(enrolmentId, groupId, groupName) {
    const options = {
      method: "POST",
      data: { enrolmentId, groupId, groupName },
      url: "/api/groups/rename",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  shareWithOthers(enrolmentId, submissionId) {
    const options = {
      method: "POST",
      data: { enrolmentId, submissionId },
      url: "/api/enrolment/shareWithOthers",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  sendExerciseToReceipients(enrolmentId) {
    const options = {
      method: "POST",
      data: { enrolmentId },
      url: "/api/enrolment/send",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  evaluate(evaluation) {
    const options = {
      method: "POST",
      data: evaluation,
      url: "/api/evaluation",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  changeGroup(enrolmentId, groupId) {
    const options = {
      method: "POST",
      data: { enrolmentId, groupId },
      url: "/api/exercise/changeGroup",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchMyGroups() {
    return axios.get("/api/my/groups").then(this.extractDataAndNotifications);
  }

  /* fetchUserList() {
    return axios.get("/api/user").then(this.extractDataAndNotifications);
  } */
  registerUser(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/user/registration",
    };
    return axios(options)
      .then(this.extractDataAndNotifications)
      .catch(this.onError);
  }

  /**
   * Update user information.
   *
   * */
  updateUser(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/user/update",
    };
    return axios(options)
      .then(this.extractDataAndNotifications)
      .catch(this.onError);
  }

  fetchPeepingData(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/peeping",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  exportPeepingData(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/peeping/export",
    };
    return axios(options);
  }

  fetchPeepingFilterData() {
    return axios
      .get("/api/peeping/filter")
      .then(this.extractDataAndNotifications);
  }

  resetPassword(resetPasswordToken) {
    return axios
      .get("/api/user/reset", {
        params: { resetPasswordToken },
      })
      .then(this.extractDataAndNotifications);
  }

  verifyResetPasswordToken(pwd, resetPasswordToken) {
    const options = {
      method: "POST",
      data: {
        ...pwd,
        token: resetPasswordToken,
      },
      url: "/api/user/updatePassword",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  forgottenPassword(data) {
    const options = {
      method: "POST",
      data,
      url: "/api/user/forgottenPassword",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(
      Array.prototype.map
        .call(
          atob(str),
          c => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`
        )
        .join("")
    );
  }

  extractDataAndNotifications(result) {
    const { data } = result;
    if (result.headers["x-hl-flash"]) {
      const flash = JSON.parse(
        decodeURIComponent(window.atob(result.headers["x-hl-flash"]))
      );
      return { data, notifications: flash || [] };
    }
    return { data, notifications: [] };
  }

  onError(result) {
    const { response } = result;
    const { data } = response;
    if (data.type === "error") {
      return { data: { error: true }, notifications: [data] };
    }
    return result;
  }

  setUserLanguage(language) {
    const options = {
      method: "POST",
      data: { language },
      url: "/api/user/setLanguage",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  // TIMELINE requests
  fetchTimelineSetup(lng) {
    const options = {
      method: "GET",
      params: { lng },
      url: "/api/timeline/setup",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchKeyWords(lng, catalog) {
    const options = {
      method: "GET",
      params: { lng, catalog },
      url: "/api/keywords",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  changeEnrolmentLockState(enrolmentId, isLocked) {
    const options = {
      method: "POST",
      data: { enrolmentId, isLocked },
      url: "/api/enrolment/lockEnrolment",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchBannerAlert(lng) {
    const options = {
      method: "GET",
      params: { lng },
      url: "/api/banner/logged",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchPublicBannerAlert(lng) {
    const options = {
      method: "GET",
      params: { lng },
      url: "/api/banner/public",
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  impersonate(emailAddress) {
    const options = {
      method: "GET",
      url: `/api/auth/impersonate?emailAddress=${emailAddress}`,
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  fetchNotifications() {
    const options = {
      method: "GET",
      url: `/api/user/notification/settings`,
    };
    return axios(options).then(this.extractDataAndNotifications);
  }

  setNotificationSetting(name, value) {
    const options = {
      method: "POST",
      url: `/api/user/notification/settings/set`,
      data: { name, value },
    };
    return axios(options).then(this.extractDataAndNotifications);
  }
}

export default DataService;
