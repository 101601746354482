import React from "react";
import classnames from "classnames";
import SortOrderButton from "../atoms/order/SortOrderButton";
import FilterText from "../atoms/input/FilterText";
import FilterSelect from "../atoms/select/FilterSelect";
import "./FilterBarVisual.scss";

/**
 * Component filter bar for sorting exercises, only visual, no logic.
 *
 * @param labels - labels for FilterBarAreas
 * @param rvpSetup - setup for rvp select
 * @param konceptSetup - setup for koncept select
 * @param b4Setup - setup for b4 select
 * @param historylabSetup - setup for historylab select
 * @param sortTextSetup - setup for text input
 * @param sortOrderButton - setup for filter order
 * @param showKeyWords - true / false - show keywords
 *
 */
const FilterBarVisual = ({ labels, fiterGroupSetup, showKeyWords = true }) => {
  const fiterSelects = fiterGroupSetup.filter(g => g.type === "select");
  const fiterText = fiterGroupSetup.filter(g => g.type === "search")[0];
  const sortOrderSetup = fiterGroupSetup.filter(g => g.type === "sort")[0];

  return (
    <div className="FilterBar">
      {showKeyWords && (
        <div className="FilterBar__keywords">
          <div className="FilterBar__label">
            <h6 title={labels.keywords}>{labels.keywords}</h6>
          </div>
          <div className="FilterBar__filters">
            {fiterSelects.map((group, index) => (
              <div
                className="FilterBar__filter"
                data-tut={group.slug}
                key={index}
              >
                <FilterSelect
                  data-cy={`filterSelect${group.placeholder}`}
                  hasValue={group.hasValue}
                  options={group.options}
                  isMulti={false}
                  placeholder={group.placeholder}
                  onChange={group.onChange}
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        className={classnames("FilterBar__fulltext", {
          "FilterBar__fulltext--fullWidth": !showKeyWords,
        })}
        data-tut="fulltext"
      >
        <div className="FilterBar__label">
          <h6 title={labels.fulltext}>{labels.fulltext}</h6>
        </div>
        <FilterText
          placeholder={fiterText.placeholder}
          onChange={fiterText.onChange}
        />
      </div>
      <div className="FilterBar__sorting">
        <div className="FilterBar__label">
          <h6 title={labels.sorting}>{labels.sorting}</h6>
        </div>
        <SortOrderButton
          options={sortOrderSetup.options}
          onChange={sortOrderSetup.onChange}
          defaultValue={sortOrderSetup.options[2]}
        />
      </div>
    </div>
  );
};

FilterBarVisual.defaultProps = {
  backgrounddivor: null,
};

export default FilterBarVisual;
