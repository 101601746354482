import React from "react";
import FilterBarVisual from "./FilterBarVisual";
import { withTranslation } from "react-i18next";
import SortExercise from "../../helpers/SortExercises";

/**
 * Component which provide filter of exercises (with logic).
 */
class FilterBar extends React.Component {
  constructor(props) {
    super(props);
    const { t } = this.props;
    const catalogSettings = this.props.catalogSettings;

    const setup = catalogSettings.filterGroupsSettings.map(group => {
      return {
        slug: group.slug,
        placeholder: group.name,
        options: [],
        hasValue: false,
        onChange: kw => this.keywordsChange(group.slug, kw),
        type: "select",
      };
    });

    // Push text search
    setup.push({
      slug: "text",
      placeholder: t("filter.text", "Vyhledejte..."),
      options: [],
      onChange: this.changeTextAction,
      type: "search",
    });

    // Push sorting search
    setup.push({
      slug: "sorting",
      onChange: option => this.orderAction(option),
      options: [
        {
          value: "difficultyAsc",
          type: "difficulty",
          label: t("filter.difficultyList.easiest", "Od nejlehčích"),
          direction: false,
        },
        {
          value: "difficultyDesc",
          type: "difficulty",
          label: t("filter.difficultyList.hardest", "Od nejtěžších"),
          direction: true,
        },
        {
          value: "timeAsc",
          type: "time",
          label: t("filter.difficultyList.timeAsc", "Od nejkratších"),
          direction: false,
        },
        {
          value: "timeDesc",
          type: "time",
          label: t("filter.difficultyList.timeDesc", "Od nejdelších"),
          direction: true,
        },
      ],
      type: "sort",
    });

    const keywordsSlugs = catalogSettings.filterGroupsSettings.reduce(
      (acc, group) => {
        acc[group.slug] = null;
        return acc;
      },
      {}
    );

    this.state = {
      setup,
      parametersForSorting: {
        text: "",
        keywords: keywordsSlugs,
        sorting: {
          type: "time",
          direction: false,
        },
      },
      initDone: false,
      showKeywords: false,
    };
    this.SorterHelper = new SortExercise();
  }

  updateFilterByProps(props) {
    if (this.state.initDone) return;
    if (props.allKeywords === null) return;
    let setup = [...this.state.setup];
    let countKW = 0;

    Object.entries(props.allKeywords).forEach(entry => {
      const [key, value] = entry;
      setup.find(e => e.slug === key).options = value.map(kw => {
        countKW++;
        return { value: kw, label: kw };
      });
    });

    this.setState(
      { setup, initDone: true, showKeywords: countKW > 0 },
      this.updateActivities
    );
  }

  /**
   * After the kw is loaded from server, make the setup for filter bar.
   *
   * @param props - New props received from parent.
   */
  componentWillReceiveProps(props) {
    this.updateFilterByProps(props);
  }

  componentDidMount() {
    this.updateFilterByProps(this.props);
  }

  render() {
    const { t } = this.props;

    return (
      <FilterBarVisual
        labels={{
          keywords: t("filter.keywords", "Filtrování dle klíčových slov"),
          fulltext: t(
            "filter.fulltext",
            "Vyhledávání v názvech a anotacích cvičení"
          ),
          sorting: t("filter.sorting", "Řazení"),
        }}
        fiterGroupSetup={this.state.setup}
        showKeyWords={this.state.showKeywords}
      />
    );
  }

  updateActivities() {
    const sortAndUpdateByFilter = () => {
      const filteredExercises = SortExercise.sortExercises(
        this.props.allExercises,
        this.state.parametersForSorting
      );
      this.props.updateExerciseByFilter(filteredExercises);
    };
    // race condition between browser and js
    setTimeout(sortAndUpdateByFilter, 0);
  }

  /**
   * Action for input change (when the text in component FilterText changed, this function is called).
   *
   * @param {String} inputString
   */
  changeTextAction = inputString => {
    if (inputString.length > 2) this.props.setIsSorting(true);
    const parametersForSorting = { ...this.state.parametersForSorting };

    parametersForSorting.text = inputString;

    this.setState({ parametersForSorting }, this.updateActivities);
  };
  /**
   * Action for order by sorting Dropdown (when the button in component SortOrderButton is clicked, this function is called).
   *
   * @param {Object} option - TYPE: type of sorting (current - Time, Difficulty), DIRECTION: direction of sorting (false=low true=high)
   */
  orderAction = option => {
    const parametersForSorting = { ...this.state.parametersForSorting };

    parametersForSorting.sorting = {
      type: option.type,
      direction: option.direction,
    };

    this.setState({ parametersForSorting }, this.updateActivities);
  };
  /**
   * Action for all selects, when they are changed (when the values in component FilterSelect changed, this function is called).
   *
   * @param {string} type - the type of the kw (rvp, koncept, b4, historylab)
   * @param {object} keywords - object which Select from react-select will send.
   */
  keywordsChange = (type, keywords) => {
    this.props.setIsSorting(true);
    const parametersForSorting = { ...this.state.parametersForSorting };
    parametersForSorting.keywords[type] = keywords && keywords.label;
    this.setState({ parametersForSorting }, this.updateActivities);
  };
}

export default withTranslation()(FilterBar);
