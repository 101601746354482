/**
 * Class which provide sorting exercises. Is used in component FilerBar.
 */
class SortExercises {
  /**
   * Helper function to decide which exercise have all the selected kw from selects.
   *
   * @param metadataKw - kw which are in exercise metadata (in klicovaSlova)
   * @param filterParameterKW - list of kw which are selected for sorting
   * @returns {boolean} - returns if exercise meets all the requirements
   */
  static isKeywordMatch(metadataKw, filterParameterKW) {
    return filterParameterKW.every(
      ([key, value]) => metadataKw[key] && metadataKw[key].includes(value)
    );
  }

  /**
   * Main function to filter exercise by text, oreder and selected kw.
   *
   * @param exercises - list of all exercises
   * @param filterPar - object which contain parameters for sorting
   *
   * @returns {object} fileredResult - returns all exercise which meet all the requirements.
   */
  static sortExercises(exercises, filterPar) {
    if (exercises === undefined || exercises.length === 0) return exercises;
    let filteredResult = exercises;

    // Filtering by text
    if (filterPar.text.length >= 3) {
      filteredResult = exercises.filter(exercise => {
        const nameLowerCase = exercise.metadata.nazev.toLowerCase();
        const description = exercise.description.toLowerCase();
        return (
          nameLowerCase.includes(filterPar.text.toLowerCase()) ||
          description.includes(filterPar.text.toLowerCase())
        );
      });
    }

    const keywordtofiter = Object.entries(filterPar.keywords).filter(
      // eslint-disable-next-line no-unused-vars
      ([_, value]) => value !== null
    );
    // Filtering by kw
    // Check if all values are null
    if (keywordtofiter.length !== 0) {
      filteredResult = filteredResult.filter(exercise =>
        SortExercises.isKeywordMatch(
          exercise.metadata.klicovaSlova,
          keywordtofiter
        )
      );
    }

    // Filtering by order - ASC / DECS
    // if (filterPar.order === "ASC") {
    //   filteredResult.sort((exerciseA, exerciseB) =>
    //     exerciseA.metadata.nazev.localeCompare(exerciseB.metadata.nazev)
    //   );
    // } else {
    //   filteredResult.sort((exerciseA, exerciseB) =>
    //     exerciseB.metadata.nazev.localeCompare(exerciseA.metadata.nazev)
    //   );
    // }

    // Filter by sortButton
    switch (filterPar.sorting.type) {
      case "time":
        filteredResult.sort((exerciseA, exerciseB) => {
          const timeA =
            !exerciseA.metadata.trvani && exerciseA.metadata.trvani !== 0
              ? Infinity
              : exerciseA.metadata.trvani;
          const timeB =
            !exerciseB.metadata.trvani && exerciseB.metadata.trvani !== 0
              ? Infinity
              : exerciseB.metadata.trvani;
          return filterPar.sorting.direction ? timeB - timeA : timeA - timeB;
        });
        break;
      case "difficulty":
        filteredResult.sort((exerciseA, exerciseB) => {
          const difficultyA = !exerciseA.metadata.obtiznost
            ? "zzz"
            : exerciseA.metadata.obtiznost;
          const difficultyB = !exerciseB.metadata.obtiznost
            ? "zzz"
            : exerciseB.metadata.obtiznost;
          return filterPar.sorting.direction
            ? difficultyB.localeCompare(difficultyA)
            : difficultyA.localeCompare(difficultyB);
        });
        break;
      default:
        filteredResult.sort((exerciseA, exerciseB) => {
          const timeA =
            !exerciseA.metadata.trvani && exerciseA.metadata.trvani !== 0
              ? Infinity
              : exerciseA.metadata.trvani;
          const timeB =
            !exerciseB.metadata.trvani && exerciseB.metadata.trvani !== 0
              ? Infinity
              : exerciseB.metadata.trvani;
          return timeA - timeB;
        });
    }

    return filteredResult;
  }
}

export default SortExercises;
