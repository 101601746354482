import React from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import DataService from "../DataService";
import withNotification from "../withNotification";
import withTour from "../withTour";
import FilterBar from "../filter/molecules/FilterBar";
import Layout from "../Layout";
import HLCard from "../components/Card/HLCard";
// import "./css/Catalog.css"; // TODO: convert this to .scss & convert cards to the Card component
import "./Catalog.scss";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Catalog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSorting: false,
      pending: false,
      modal: false,
      error: null,
      filteredAndSortedData: [],
      exercises: [],
      currentBreakpoint: "lg",
      mounted: false,
      allKeywords: null,
      catalogMetadata: {
        name: "Katalog",
        settings: {
          showFilter: false,
        },
      },
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };
  refresh = lng => {
    if (!lng) {
      lng = i18n.language;
    }
    const catalog = this.props.match.path.startsWith("/")
      ? this.props.match.path.slice(1)
      : this.props.match.path;

    this.setState({ isLoading: true });
    new DataService().fetchCatalog(this.props.match.path, lng).then(
      result => {
        const exercises = result.data.exercises || [];
        const filteredAndSortedData = result.data.exercises || [];
        this.setState({
          error: null,
          exercises,
          filteredAndSortedData,
          catalogMetadata:
            result.data.catalogMetadata || this.state.catalogMetadata,
          isLoading: false,
        });
      },
      error =>
        this.setState({
          error,
          isLoading: false,
        })
    );
    new DataService().fetchKeyWords(lng, catalog).then(
      result => {
        this.setState({
          error: null,
          allKeywords: result.data.keyWords,
          isLoading: false,
        });
      },
      error =>
        this.setState({
          error,
          isLoading: false,
        })
    );
  };

  componentDidMount() {
    this.refresh();
    this.setState({ mounted: true });
    const that = this;
    i18n.on("languageChanged", function (lng) {
      that.refresh(lng);
    });
  }

  generateCatalog() {
    const { filteredAndSortedData } = this.state;
    return filteredAndSortedData.map(exercise => (
      <Link
        key={exercise.id}
        to={{
          pathname: exercise.catalogUrl,
          state: {
            exercise,
            returnTo: this.props.location.pathname,
          },
        }}
      >
        <HLCard
          image={exercise.thumb_image_url}
          name={exercise.name}
          description={exercise.metadata.anotace.ucitel}
          authors={exercise.metadata.autor}
          difficulty={exercise.metadata.obtiznost}
          mobileData={exercise.metadata.mobileData}
          time={exercise.estimatedTime}
          keywords={exercise.metadata.klicovaSlova}
          // color={`hsl(${Object.values(exercise.metadata.color.hsv).join(',')})`}
          color={
            exercise.metadata.color
              ? `hsl(${exercise.metadata.color.hsv.h}, ${
                  exercise.metadata.color.hsv.s
                }%, ${exercise.metadata.color.hsv.v - 20}%)`
              : false
          }
          variant="catalog"
        />
      </Link>
    ));
  }

  getCatalog() {
    if (this.state.isLoading) {
      return <div className="text-center">{this.props.t("pleaseWait")}</div>;
    }

    return <Row className="grid">{this.generateCatalog()}</Row>;
  }

  render() {
    return (
      <Layout
        title={this.state.catalogMetadata.name}
        isTitleCentered={true}
        isFullWidth={true}
        className="Catalog"
        style={{ position: "relative" }}
      >
        <Layout.Content>
          <Row>
            <Col
              className={`loading-catalog text-center position-absolute h-100 ${
                this.state.isSorting ? "" : "hidden"
              }`}
            >
              {/*<FontAwesomeIcon icon="spinner" spin />{" "}*/}
              {/*{this.props.t("pleaseWait")}*/}
            </Col>
          </Row>
          {!this.state.isLoading &&
            this.state.catalogMetadata.settings.showFilter && this.state.catalogMetadata.settings.filterGroupsSettings && (
              <FilterBar
                allExercises={this.state.exercises}
                allKeywords={this.state.allKeywords}
                catalogSettings={this.state.catalogMetadata.settings}
                updateExerciseByFilter={this.updateExerciseByFilter}
                setIsSorting={this.setIsSorting}
              />
            )}
          {this.getCatalog()}
        </Layout.Content>
      </Layout>
    );
  }

  updateExerciseByFilter = filteredAndSortedData => {
    this.setState({ filteredAndSortedData, isSorting: false });
  };

  setIsSorting = isSorting => {
    this.setState({ isSorting });
  };
}

const tourSteps = t => {
  const step = value => t("catalog.tutorial." + value);
  const steps = [
    { selector: "[data-tut='rvp']", content: step("rvp"), position: "bottom" },
    {
      selector: "[data-tut='koncept']",
      content: step("concepts"),
      position: "bottom",
    },
    { selector: "[data-tut='b4']", content: step("b4"), position: "bottom" },
    {
      selector: "[data-tut='historylab']",
      content: step("historylab"),
      position: "bottom",
    },
    {
      selector: "[data-tut='fulltext']",
      content: step("fulltext"),
      position: "bottom",
    },
  ];
  return steps;
};

export default withTranslation()(
  withNotification(withTour(Catalog, tourSteps))
);
